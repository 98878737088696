
<mat-card class="formaWrap">

  <a class="btn" routerLink="/storno">Storniranje</a>
  <br />
  <mat-form-field appearance="fill" class="input">
    <mat-label></mat-label>
    <select #ref matNativeControl (change)='onOptionsSelected(ref.value)'>
      <option value="0">Insitiut za majku i dete</option>
      <option value="1">Univerzitetski klinički centar Srbije</option>
    </select>
  </mat-form-field>

  <form class="forma" [formGroup]="faktura" (ngSubmit)="Submit()">

    <mat-form-field appearance="fill" class="input">
      <mat-label>Tip</mat-label>
      <select matNativeControl required [(value)]="type">
        <option value="1">1 - isporuke izvršene za osigurana lica Republičkog fonda</option>
        <option value="2">2 - isporuke izvršene za ostala lica čije lečenje se ne finansira na teret sredstava Republičkog fonda</option>
      </select>
    </mat-form-field>

    <mat-form-field class="input" title="Podračun budžetskih sredstava za ugovorene namene zdravstvene ustanove">
      <mat-label>Podračun budžetskih sredstava za ugovorene namene zdravstvene ustanove</mat-label>
      <input matInput formControlName="racun1" required>
    </mat-form-field>
    <br />
    <mat-form-field class="input" title="Podračun izuzet iz blokade za ugovorene namene zdravstvene ustanove">
      <mat-label>Podračun izuzet iz blokade za ugovorene namene zdravstvene ustanove</mat-label>
      <input matInput formControlName="racun2">
    </mat-form-field>

    <mat-form-field class="input">
      <mat-label>Matični broj dobavljača</mat-label>
      <input matInput formControlName="MB" required>
    </mat-form-field>
    <br />
    <mat-form-field class="input">
      <mat-label>PIB dobavljača</mat-label>
      <input matInput formControlName="PIB" required>
    </mat-form-field>

    <mat-form-field class="input">
      <mat-label>Jedinstveni broj budžetskog korisnika dobavljača. JBBK</mat-label>
      <input matInput formControlName="JBBK">
    </mat-form-field>
    <br />
    <mat-form-field class="input">
      <mat-label>Matični broj zdravstvene ustanove</mat-label>
      <input matInput formControlName="MBZu" required>
    </mat-form-field>

    <mat-form-field class="input">
      <mat-label>Poreski identifikacioni broj zdravstvene ustanove</mat-label>
      <input matInput formControlName="PIBZu" required>
    </mat-form-field>
    <br />
    <mat-form-field class="input">
      <mat-label>Naziv poslovne banke dobavljača</mat-label>
      <input matInput formControlName="banka" required>
    </mat-form-field>

    <mat-form-field class="input">
      <mat-label>Broj ugovora između zdravstvene ustanove i dobavljača</mat-label>
      <input matInput formControlName="brojUgovora" required>
    </mat-form-field>
    <br />
    <mat-form-field class="input">
      <mat-label>Broj tekućeg računa dobavljača</mat-label>
      <input matInput formControlName="tekuciRacun" required>
    </mat-form-field>

    <mat-form-field class="input">
      <mat-label>Broj (oznaka) računa dobavljača</mat-label>
      <input matInput formControlName="brojRacuna" required>
    </mat-form-field>
    <br />
    <mat-form-field class="input">
      <mat-label>Datum izdavanja računa dobavljača</mat-label>
      <input matInput formControlName="datumIzdavanja" required>
    </mat-form-field>

    <mat-form-field class="input">
      <mat-label>Datum prometa računa dobavljača</mat-label>
      <input matInput formControlName="datumPrometa" required>
    </mat-form-field>
    <br />
    <mat-form-field class="input">
      <mat-label>Iznos fakture dobavljača bez iskazanog PDV-a</mat-label>
      <input matInput formControlName="iznosFaktureBezPdv" required>
    </mat-form-field>

    <mat-form-field class="input">
      <mat-label>Iznos PDV-a</mat-label>
      <input matInput formControlName="PDV" required>
    </mat-form-field>
    <br />
    <mat-form-field class="input">
      <mat-label>Iznos fakture dobavljača sa iskazanim PDV-om</mat-label>
      <input matInput formControlName="iznosFakture" required>
    </mat-form-field>

    <mat-form-field class="input">
      <mat-label>Valuta – rok za plaćanje fakture dobavljača</mat-label>
      <input matInput formControlName="valuta" required>
    </mat-form-field>
    <br />
    <mat-form-field class="input">
      <mat-label>Ugovorena naknada sa zdravstvenom ustanovom (KPP iz šifarnika RFZO)</mat-label>
      <input matInput formControlName="kpp" required>
    </mat-form-field>

    <mat-form-field class="input">
      <mat-label>Poziv na broj dobavljača</mat-label>
      <input matInput formControlName="pozivBroj" required>
    </mat-form-field>
    <br />
    <mat-form-field class="input">
      <mat-label>Šifra plaćanja dobavljača</mat-label>
      <input matInput formControlName="sifraPlacanja" required>
    </mat-form-field>
    <mat-form-field appearance="fill" class="input">
      <mat-label>Cjn</mat-label>
      <select matNativeControl required [(value)]="nijeCjn">
        <option value="0">Faktura po nabavci sprovedenoj od strane zdravstvene ustanove</option>
        <option value="1">Faktura po centralizovanoj javnoj nabavci RFZO</option>
      </select>
    </mat-form-field>

    <button mat-raised-button color="primary">Pošalji</button>
  </form>
</mat-card>
