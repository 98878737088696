import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import { RfzoService } from '../rfzo.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  public type: any = 1;
  public nijeCjn: any = 0;
  public MBZu: string = "07046219";
  public PIBZu: string = "100136676";

  constructor(private _service: RfzoService) { }

  ngOnInit(): void {
  }

  faktura: FormGroup = new FormGroup({
    type: new FormControl(this.type, [Validators.required]),
    racun1: new FormControl('', [Validators.required]),
    racun2: new FormControl(''),
    MB: new FormControl(environment.MB, [Validators.required]),
    PIB: new FormControl(environment.PIB, [Validators.required]),
    JBBK: new FormControl('', []),
    MBZu: new FormControl(this.MBZu, [Validators.required]),
    PIBZu: new FormControl(this.PIBZu, [Validators.required]),
    banka: new FormControl(environment.BANKA, [Validators.required]),
    brojUgovora: new FormControl('', [Validators.required]),
    tekuciRacun: new FormControl(environment.RACUN, [Validators.required]),
    brojRacuna: new FormControl('', [Validators.required]),
    datumIzdavanja: new FormControl('', [Validators.required]),
    datumPrometa: new FormControl('', [Validators.required]),
    iznosFaktureBezPdv: new FormControl('', [Validators.required]),
    PDV: new FormControl('', [Validators.required]),
    iznosFakture: new FormControl('', [Validators.required]),
    valuta: new FormControl('', [Validators.required]),
    kpp: new FormControl('', [Validators.required]),
    pozivBroj: new FormControl('', [Validators.required]),
    sifraPlacanja: new FormControl('', [Validators.required]),
    nijeCjn: new FormControl(this.nijeCjn, [Validators.required])
  });

  public Submit() {
    console.log(this.faktura);
    if (this.faktura.valid) {
      this._service.send(this.faktura).subscribe(res => {
        var json = JSON.parse(res._body);
        alert(json.response.msg);
      });
    }
  }

  onOptionsSelected(value: string) {
    if (+value == 0) {
      this.MBZu = "07046219";
      this.PIBZu = "100136676";      
    }
    else if (+value == 1) {
      this.MBZu = "07089503";
      this.PIBZu = "100118429";
    }
    this.faktura.controls["MBZu"].setValue(this.MBZu);
    this.faktura.controls["PIBZu"].setValue(this.PIBZu);
  }

}
